import { createReducer, on } from '@ngrx/store';
import { pushNotificationsDevicesActions } from '../actions/push-notifications-devices.actions';
import { PushNotificationDevice } from '../models';

export interface PushNotificationsDevicesState {
    loaded: boolean | null;
    loading: boolean | null;
    devices: PushNotificationDevice[];
}

export const initialState: PushNotificationsDevicesState = {
    loaded: null,
    loading: null,
    devices: [],
};

export const pushNotificationsDevicesReducer = createReducer(
    initialState,
    on(
        pushNotificationsDevicesActions.getDevice,
        (state): PushNotificationsDevicesState => ({
            ...state,
            loading: true,
        }),
    ),

    on(
        pushNotificationsDevicesActions.getDeviceSuccess,
        (state, action): PushNotificationsDevicesState => ({
            ...state,
            devices: action.devices,
            loading: false,
            loaded: true,
        }),
    ),

    on(
        pushNotificationsDevicesActions.getDeviceError,
        (state): PushNotificationsDevicesState => ({
            ...state,
            loading: false,
            loaded: false,
        }),
    ),

    on(
        pushNotificationsDevicesActions.clear,
        (): PushNotificationsDevicesState => ({
            ...initialState,
        }),
    ),
);
