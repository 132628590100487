import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PushNotificationsDevicesState } from '../reducers/push-notifications-devices.reducer';
import { PUSH_NOTIFICATIONS_DEVICES_KEY } from '../keys';

const selectState = createFeatureSelector<PushNotificationsDevicesState>(PUSH_NOTIFICATIONS_DEVICES_KEY);

const selectLoaded = createSelector(selectState, (state: PushNotificationsDevicesState) => state.loaded);
const selectLoading = createSelector(selectState, (state: PushNotificationsDevicesState) => state.loading);
const selectPushNotificationsDevices = createSelector(selectState, (state: PushNotificationsDevicesState) => state.devices);

export { selectLoaded, selectLoading, selectPushNotificationsDevices };
