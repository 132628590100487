import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PushNotificationsState } from '../reducers/push-notifications.reducer';
import { PUSH_NOTIFICATIONS_KEY } from '../keys';

const selectState = createFeatureSelector<PushNotificationsState>(PUSH_NOTIFICATIONS_KEY);

const selectLoaded = createSelector(selectState, (state: PushNotificationsState) => state.loaded);
const selectPushNotificationState = createSelector(selectState, (state: PushNotificationsState) => state.state);
const selectToken = createSelector(selectState, (state: PushNotificationsState) => state.token);
const selectPushNotification = createSelector(selectState, (state: PushNotificationsState) => state.notification);

export { selectLoaded, selectPushNotificationState, selectToken, selectPushNotification };
