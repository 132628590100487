import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { PushNotificationsFacade } from '../facades/push-notifications.facade';

export function pushNotificationsUnregister<T>(): CanDeactivateFn<T> {
    return () => {
        const pushNotificationsFacade = inject(PushNotificationsFacade);

        pushNotificationsFacade.unregister();

        return true;
    };
}
