import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { PushNotificationsFacade } from '../facades/push-notifications.facade';
import { PushNotificationsDevicesFacade } from '../facades/push-notifications-devices.facade';

export function pushNotificationsCleanup<T>(): CanDeactivateFn<T> {
    return () => {
        const pushNotificationsFacade = inject(PushNotificationsFacade);
        const pushNotificationsDevicesFacade = inject(PushNotificationsDevicesFacade);

        pushNotificationsFacade.clear();
        pushNotificationsDevicesFacade.clear();

        return true;
    };
}
