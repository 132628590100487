import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { combineLatest, map, mergeMap, of, take, tap } from 'rxjs';
import { PushNotificationsDevicesFacade } from '../facades/push-notifications-devices.facade';
import { PushNotificationsFacade } from '../facades/push-notifications.facade';

export function pushNotificationsDevicesRegister(): CanActivateFn {
    return () => {
        const pushNotificationsDevicesFacade = inject(PushNotificationsDevicesFacade);
        const pushNotificationsFacade = inject(PushNotificationsFacade);

        return combineLatest([pushNotificationsFacade.state$, pushNotificationsDevicesFacade.device$]).pipe(
            mergeMap(([state]) => {
                if (state === 'granted') {
                    return pushNotificationsFacade.token$.pipe(
                        tap((token) => {
                            if (token) {
                                pushNotificationsDevicesFacade.registerDevice(token);
                            }
                        }),
                    );
                }
                return of(true);
            }),
            take(1),
            map(() => true),
        );
    };
}
