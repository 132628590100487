import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PushNotificationDevice } from '../models';

export const pushNotificationsDevicesActions = createActionGroup({
    source: 'Push Notifications Devices',
    events: {
        'Get Device': emptyProps(),
        'Get Device Success': props<{ devices: PushNotificationDevice[] }>(),
        'Get Device Error': emptyProps(),

        'Update Device': props<{ enabled: boolean }>(),
        'Update Device Success': emptyProps(),
        'Update Device Error': emptyProps(),

        'Register Device': props<{ token: string }>(),
        'Register Device Success': emptyProps(),
        'Register Device Error': emptyProps(),

        'Unregister Device': emptyProps(),
        'Unregister Device Success': emptyProps(),
        'Unregister Device Error': emptyProps(),

        Clear: emptyProps(),
    },
});
