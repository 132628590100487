/* eslint-disable @typescript-eslint/no-empty-function */
import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';
import { PushNotificationState } from '../types';
import { PushNotificationSchema } from '@capacitor/push-notifications';

@Injectable()
export class EventService {
    status$ = of<PushNotificationState>('unsupported');
    register$ = of<PushNotificationState>('unsupported');
    notification$ = new Subject<PushNotificationSchema>();

    readonly token$ = new Subject<string>();

    register(): void {}
    status(): void {}
    unregister(): void {}
}
