import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { pushNotificationsDevicesActions } from '../actions/push-notifications-devices.actions';
import { selectLoaded, selectLoading, selectPushNotificationsDevices } from '../selectors/push-notifications-devices.selector';
import { combineLatest, from, map } from 'rxjs';
import { Device } from '@capacitor/device';

@Injectable({
    providedIn: 'root',
})
export class PushNotificationsDevicesFacade {
    private readonly store = inject(Store);

    readonly loaded$ = this.store.select(selectLoaded);
    readonly loading$ = this.store.select(selectLoading);
    readonly devices$ = this.store.select(selectPushNotificationsDevices);

    readonly device$ = combineLatest([this.devices$, from(Device.getId())]).pipe(
        map(([devices, devideId]) => {
            return devices.find((device) => device.deviceId === devideId.identifier) ?? null;
        }),
    );

    getDevices(): void {
        this.store.dispatch(pushNotificationsDevicesActions.getDevice());
    }

    updateDevice(enabled: boolean): void {
        this.store.dispatch(pushNotificationsDevicesActions.updateDevice({ enabled }));
    }

    registerDevice(token: string): void {
        this.store.dispatch(pushNotificationsDevicesActions.registerDevice({ token }));
    }

    unregisterDevice(): void {
        this.store.dispatch(pushNotificationsDevicesActions.unregisterDevice());
    }

    clear(): void {
        this.store.dispatch(pushNotificationsDevicesActions.clear());
    }
}
