import { DeviceId, DeviceInfo } from '@capacitor/device';

export class DeviceRegisterValueDto {
    platform: 'ios' | 'android' | 'web';
    deviceId: string;
    token: string;

    constructor(devideInfo: DeviceInfo, deviceId: DeviceId, token: string) {
        this.platform = devideInfo.platform;
        this.deviceId = deviceId.identifier;
        this.token = token;
    }
}
