import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of } from 'rxjs';
import { pushNotificationsDevicesActions } from '../actions/push-notifications-devices.actions';
import { HttpService } from '../services/http.service';

@Injectable()
export class PushNotificationsDevicesEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);

    getDevices$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                pushNotificationsDevicesActions.getDevice,
                pushNotificationsDevicesActions.registerDeviceSuccess,
                pushNotificationsDevicesActions.registerDeviceError,
                pushNotificationsDevicesActions.updateDeviceSuccess,
                pushNotificationsDevicesActions.updateDeviceError,
                pushNotificationsDevicesActions.unregisterDeviceSuccess,
                pushNotificationsDevicesActions.unregisterDeviceError,
            ),
            mergeMap(() => {
                return this.httpService.getDevices().pipe(
                    map((devices) => pushNotificationsDevicesActions.getDeviceSuccess({ devices })),
                    catchError(() => of(pushNotificationsDevicesActions.getDeviceError())),
                );
            }),
        );
    });

    updateDevice$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(pushNotificationsDevicesActions.updateDevice),
            mergeMap(({ enabled }) => {
                return this.httpService.updateDevice(enabled).pipe(
                    map(() => pushNotificationsDevicesActions.updateDeviceSuccess()),
                    catchError(() => of(pushNotificationsDevicesActions.updateDeviceError())),
                );
            }),
        );
    });

    registerDevice$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(pushNotificationsDevicesActions.registerDevice),
            mergeMap(({ token }) => {
                return this.httpService.registerDevice(token).pipe(
                    map(() => pushNotificationsDevicesActions.registerDeviceSuccess()),
                    catchError(() => of(pushNotificationsDevicesActions.registerDeviceError())),
                );
            }),
        );
    });

    unregisterDevice$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(pushNotificationsDevicesActions.unregisterDevice),
            mergeMap(() => {
                return this.httpService.unregisterDevice().pipe(
                    map(() => pushNotificationsDevicesActions.unregisterDeviceSuccess()),
                    catchError(() => of(pushNotificationsDevicesActions.unregisterDeviceError())),
                );
            }),
        );
    });
}
