import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { map, take, tap } from 'rxjs';
import { PushNotificationsFacade } from '../facades/push-notifications.facade';

export function pushNotificationsRegister(): CanActivateFn {
    return () => {
        const pushNotificationsFacade = inject(PushNotificationsFacade);

        return pushNotificationsFacade.state$.pipe(
            tap((state) => {
                if (state === 'prompt' || state === 'prompt-with-rationale' || state === 'granted') {
                    pushNotificationsFacade.register();
                }
            }),
            take(1),
            map(() => true),
        );
    };
}
