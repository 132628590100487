import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { pushNotificationsActions } from '../actions/push-notifications.actions';
import { selectLoaded, selectPushNotificationState, selectToken, selectPushNotification } from '../selectors/push-notifications.selector';
import { filter } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PushNotificationsFacade {
    private readonly store = inject(Store);

    readonly loaded$ = this.store.select(selectLoaded);
    readonly notification$ = this.store.select(selectPushNotification).pipe(filter((value) => value !== null));
    readonly state$ = this.store.select(selectPushNotificationState);
    readonly token$ = this.store.select(selectToken);

    register(): void {
        this.store.dispatch(pushNotificationsActions.register());
    }

    status(): void {
        this.store.dispatch(pushNotificationsActions.status());
    }

    unregister(): void {
        this.store.dispatch(pushNotificationsActions.unregister());
    }

    clear(): void {
        this.store.dispatch(pushNotificationsActions.clear());
    }
}
