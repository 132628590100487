import { DeviceId, DeviceInfo } from '@capacitor/device';

export class DeviceValueDto {
    platform: 'ios' | 'android' | 'web';
    deviceId: string;
    enabled: boolean;

    constructor(devideInfo: DeviceInfo, deviceId: DeviceId, status: boolean) {
        this.platform = devideInfo.platform;
        this.deviceId = deviceId.identifier;
        this.enabled = status;
    }
}
