import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs';
import { pushNotificationsActions } from '../actions/push-notifications.actions';
import { EventService } from '../services/event.service';

@Injectable()
export class PushNotificationsEffects {
    private readonly actions$ = inject(Actions);
    private readonly eventService = inject(EventService);

    register$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(pushNotificationsActions.register),
                tap(() => this.eventService.register()),
            );
        },
        { dispatch: false },
    );

    registerCallback$ = createEffect(() => {
        return this.eventService.register$.pipe(map((state) => pushNotificationsActions.registerCallback({ state })));
    });

    status$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(pushNotificationsActions.status),
                tap(() => this.eventService.status()),
            );
        },
        { dispatch: false },
    );

    statusCallback$ = createEffect(() => {
        return this.eventService.status$.pipe(map((state) => pushNotificationsActions.statusCallback({ state })));
    });

    unregister$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(pushNotificationsActions.unregister),
                tap(() => this.eventService.unregister()),
            );
        },
        { dispatch: false },
    );

    tokenCallback$ = createEffect(() => {
        return this.eventService.token$.pipe(map((token) => pushNotificationsActions.tokenCallback({ token })));
    });

    notification$ = createEffect(() => {
        return this.eventService.notification$.pipe(map((notification) => pushNotificationsActions.notificationReceived({ notification })));
    });
}
