import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { PushNotificationsDevicesFacade } from '../facades/push-notifications-devices.facade';

export function pushNotificationsDevicesUnregister<T>(): CanDeactivateFn<T> {
    return () => {
        const pushNotificationsDevicesFacade = inject(PushNotificationsDevicesFacade);

        pushNotificationsDevicesFacade.unregisterDevice();

        return true;
    };
}
