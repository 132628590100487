import { createReducer, on } from '@ngrx/store';
import { pushNotificationsActions } from '../actions/push-notifications.actions';
import { PushNotificationState } from '../types';
import { PushNotificationSchema } from '@capacitor/push-notifications';

export interface PushNotificationsState {
    loaded: boolean | null;
    state: PushNotificationState | null;
    token: string | null;
    notification: PushNotificationSchema | null;
}

export const initialState: PushNotificationsState = {
    loaded: null,
    state: null,
    token: null,
    notification: null,
};

export const pushNotificationsReducer = createReducer(
    initialState,
    on(
        pushNotificationsActions.statusCallback,
        (state, action): PushNotificationsState => ({
            ...state,
            loaded: true,
            state: action.state,
        }),
    ),

    on(
        pushNotificationsActions.registerCallback,
        (state, action): PushNotificationsState => ({
            ...state,
            state: action.state,
        }),
    ),

    on(
        pushNotificationsActions.tokenCallback,
        (state, action): PushNotificationsState => ({
            ...state,
            token: action.token,
        }),
    ),

    on(
        pushNotificationsActions.notificationReceived,
        (state, action): PushNotificationsState => ({
            ...state,
            notification: action.notification,
        }),
    ),

    on(
        pushNotificationsActions.unregister,
        (state): PushNotificationsState => ({
            ...state,
            loaded: null,
            state: null,
            notification: null,
        }),
    ),
);
