import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiResponse } from '@app/shared/models';
import { forkJoin, from, map, mergeMap } from 'rxjs';
import { PushNotificationDeviceDto } from '../models/push-notification.dto';
import { PushNotificationDevice } from '../models/push-notification';
import { Device } from '@capacitor/device';
import { DeviceValueDto } from '../models/device.value.dto';
import { DeviceRegisterValueDto } from '../models/device-register.value.dto';
import { DeviceUnregisterValueDto } from '../models/device-unregister.value.dto';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getDevices() {
        return this.httpClient
            .get<ApiResponse<PushNotificationDeviceDto[]>>(`api/v1/push-notifications/status`)
            .pipe(map((response) => response.data.map((item) => new PushNotificationDevice(item))));
    }

    updateDevice(enabled: boolean) {
        return forkJoin([from(Device.getInfo()), from(Device.getId())]).pipe(
            mergeMap(([deviceInfo, deviceId]) => {
                const value = new DeviceValueDto(deviceInfo, deviceId, enabled);

                return this.httpClient.put<unknown>(`api/v1/push-notifications/status`, value);
            }),
        );
    }

    registerDevice(token: string) {
        return forkJoin([from(Device.getInfo()), from(Device.getId())]).pipe(
            mergeMap(([deviceInfo, deviceId]) => {
                const value = new DeviceRegisterValueDto(deviceInfo, deviceId, token);

                return this.httpClient.put<unknown>(`api/v1/push-notifications/register`, value);
            }),
        );
    }

    unregisterDevice() {
        return forkJoin([from(Device.getInfo()), from(Device.getId())]).pipe(
            mergeMap(([deviceInfo, deviceId]) => {
                const value = new DeviceUnregisterValueDto(deviceInfo, deviceId);

                return this.httpClient.post<unknown>(`api/v1/push-notifications/unregister`, value);
            }),
        );
    }
}
