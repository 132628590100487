import { DeviceId, DeviceInfo } from '@capacitor/device';

export class DeviceUnregisterValueDto {
    platform: 'ios' | 'android' | 'web';
    deviceId: string;

    constructor(devideInfo: DeviceInfo, deviceId: DeviceId) {
        this.platform = devideInfo.platform;
        this.deviceId = deviceId.identifier;
    }
}
