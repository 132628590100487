import { PushNotificationDeviceDto } from './push-notification.dto';

export class PushNotificationDevice {
    platform: 'ios' | 'android' | 'web';
    deviceId: string;
    enabled: boolean;
    constructor(dto: PushNotificationDeviceDto) {
        this.platform = dto.platform;
        this.deviceId = dto.device_id;
        this.enabled = dto.enabled;
    }
}
