import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PushNotificationState } from '../types';
import { PushNotificationSchema } from '@capacitor/push-notifications';

export const pushNotificationsActions = createActionGroup({
    source: 'Push Notifications',
    events: {
        Register: emptyProps(),
        'Register Callback': props<{ state: PushNotificationState }>(),
        Unregister: emptyProps(),

        Status: emptyProps(),
        'Status Callback': props<{ state: PushNotificationState }>(),

        'Token Callback': props<{ token: string }>(),
        'Notification Received': props<{ notification: PushNotificationSchema }>(),

        Clear: emptyProps(),
    },
});
