import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { PUSH_NOTIFICATIONS_DEVICES_KEY, PUSH_NOTIFICATIONS_KEY } from './keys';
import { PushNotificationsEffects } from './effects/push-notifications.effects';
import { pushNotificationsReducer } from './reducers/push-notifications.reducer';
import { EventService } from './services/event.service';
import { EventMobileService } from './services/event-mobile.service';
import { Capacitor } from '@capacitor/core';
import { PushNotificationsDevicesEffects } from './effects/push-notifications-devices.effects';
import { pushNotificationsDevicesReducer } from './reducers/push-notifications-devices.reducer';

const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(PUSH_NOTIFICATIONS_KEY, pushNotificationsReducer),
        StoreModule.forFeature(PUSH_NOTIFICATIONS_DEVICES_KEY, pushNotificationsDevicesReducer),
        EffectsModule.forFeature([PushNotificationsEffects, PushNotificationsDevicesEffects]),
    ],
    providers: [
        isPushNotificationsAvailable
            ? {
                  provide: EventService,
                  useClass: EventMobileService,
              }
            : EventService,
    ],
})
export class PushNotificationsStoreModule {}
