import { Injectable } from '@angular/core';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { from, map, Subject, take, tap } from 'rxjs';
import { EventService } from './event.service';
import { PushNotificationState } from '../types/state';

@Injectable()
export class EventMobileService extends EventService {
    override status$ = new Subject<PushNotificationState>();
    override register$ = new Subject<PushNotificationState>();

    constructor() {
        super();
        PushNotifications.addListener('registration', (token: Token) => this.token$.next(token.value));
        PushNotifications.addListener('registrationError', (error) => console.log(error));
        PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => this.notification$.next(notification));
        PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => this.notification$.next(action.notification));
    }

    override register() {
        from(PushNotifications.requestPermissions())
            .pipe(
                tap((status) => {
                    if (status.receive === 'granted') {
                        PushNotifications.register();
                    }
                }),
                tap(() => this.status()),
                take(1),
            )
            .subscribe();
    }

    override status() {
        from(PushNotifications.checkPermissions())
            .pipe(
                map((status) => this.status$.next(status.receive)),
                take(1),
            )
            .subscribe();
    }

    override unregister() {
        from(PushNotifications.unregister())
            .pipe(
                tap(() => this.status()),
                take(1),
            )
            .subscribe();
    }
}
